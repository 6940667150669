import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';

import { VictoriesSimpleBannerProps } from './models';

import './VictoriesSimpleBanner.scss';

const VictoriesSimpleBanner: FC<VictoriesSimpleBannerProps> = ({ data: { title, text, mode } }) => (
  <Row
    data-testid="victories-simple-banner"
    className={classNames('victories-simple-banner', {
      [`victories-simple-banner--${mode}`]: mode,
    })}
  >
    <Col lg={12}>
      <DangerouslySetInnerHtml className="victories-simple-banner__title" html={title} />

      {text ? (
        <DangerouslySetInnerHtml className="victories-simple-banner__text" html={text} />
      ) : null}
    </Col>
  </Row>
);

export const query = graphql`
  fragment VictoriesSimpleBannerFragment on VictoriesSimpleBannerType {
    title
    text
    mode
  }
`;

export default VictoriesSimpleBanner;
