import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import { OG_PAGE_TYPES } from 'common/Seo/constants';
import TermsText from 'components/vanishVictories/TermsText';
import VictoriesSimpleBanner from 'components/vanishVictories/VictoriesSimpleBanner';
import createBreadcrumbs from 'utils/createBreadcrumbs';

import { VanishVictoriesTermsPageProps } from './models';

import './VanishVictoriesTermsPage.scss';

const VanishVictoriesTermsPage: FC<VanishVictoriesTermsPageProps> = ({
  data: {
    vanishVictoriesTermsPage: { seo, langProps, victoriesSimpleBanner, termsText, footerType },
    allSitePage: { nodes },
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
    pagePathname,
  },
  location: { pathname },
}) => {
  const breadcrumbs = createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname);

  return (
    <Layout
      className="vanish-victories-terms-page"
      {...{
        seo,
        seoOGType: OG_PAGE_TYPES.WEBSITE,
        langProps,
        breadcrumbs,
        pagePathname,
        additionalSchema: 'WebPage',
      }}
      footerType={footerType}
    >
      <VictoriesSimpleBanner data={victoriesSimpleBanner?.[0]} />
      <TermsText data={termsText?.[0]} />
    </Layout>
  );
};

export const query = graphql`
  query($link: String) {
    vanishVictoriesTermsPage(link: { eq: $link }) {
      seo {
        ...SEOStructureFragment
      }
      langProps {
        urls {
          ...languageUrls
        }
        lang
      }
      pageName
      footerType {
        type
      }
      victoriesSimpleBanner {
        ...VictoriesSimpleBannerFragment
      }
      termsText {
        ...TermsTextFragment
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
  }
`;

export default VanishVictoriesTermsPage;
