import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { graphql } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';

import { TermsTextProps } from './models';

import './TermsText.scss';

const TermsText: FC<TermsTextProps> = ({ data: { text } }) => (
  <Row data-testid="terms-text" className="terms-text">
    <Col lg={12}>
      <DangerouslySetInnerHtml className="terms-text__text" html={text} />
    </Col>
  </Row>
);

export const query = graphql`
  fragment TermsTextFragment on TermsTextType {
    text
  }
`;

export default TermsText;
